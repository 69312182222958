import React, { useEffect, useState } from "react";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap";
import useApi from "hooks/useApi";
import useAbility from "hooks/useAbility";
import { useParams } from "react-router-dom";
import lclApi from "api/LCLBK";
import Spinner from "../../../components/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader";
import env from "env";
import Gallery from "components/common/Gallery";
import DocsCard from "components/common/DocsCard";
//content
import content from "./content";

//common
import commenContent from "components/common/content";
import useAlert from "hooks/useAlert";
import FilePicker from "components/common/FilePicker";
import Tables from "components/common/CustomTable";
import SpTable from "components/common/SpTable";

function DetailLCL() {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  // params
  const { id } = useParams();

  const checkAbility = useAbility("LCL");

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // apis
  const getOneLCL = useApi(lclApi.getLCL);
  const uploadImgLCL = useApi(lclApi.uploadImgLCL);

  // states
  const [data, setData] = useState({});
  const [imgs, setImgs] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [approveModal, setApproveModal] = useState(false);

  const [headers, setHeaders] = useState([
    commenContent.spCode[culture],
    commenContent.rmbAmount[culture],
    commenContent.usdAmount[culture],
    commenContent.rate[culture],
    commenContent.date[culture],
    commenContent.bankAccount[culture],
  ]);

  const [columns, setColumns] = useState([
    "spCode",
    "rmB_Amount",
    "usD_Amount",
    "rate",
    "createdOn",
    "bankAccount",
  ]);

  const thisStatusIndex = env.followUpStatus.findIndex(
    (status) => status.key === data.followUpStatus
  );

  const isFile = (file) => {
    const extension = file.substr(file.lastIndexOf(".") - file.length + 1);

    if (extension === "pdf") return true;
    if (extension === "docx" || extension === "dot") return true;
    if (extension === "pptx" || extension === "ppt" || extension === "ppsx")
      return true;
    if (
      extension === "xlsx" ||
      extension === "xlsm" ||
      extension === "xls" ||
      extension === "xlsb"
    )
      return true;
    return false;
  };

  const files = [...(data?.imges || "")].filter((item) => isFile(item));
  const imges = [...(data?.imges || "")].filter((img) => !isFile(img));
  const swiftFiles = [...(data.swift?.split(",") || "")].filter((item) =>
    isFile(item)
  );
  const swiftImges = [...(data.swift?.split(",") || "")].filter(
    (img) => !isFile(img)
  );

  const getLCL = async () => {
    const res = await getOneLCL.request(id);

    if (res.status === 200) {
      Object.keys(res.data.data).forEach((key) => {
        if (key.includes("date") || key.includes("Date")) {
          res.data.data[key] = res.data.data[key]
            ? res.data.data[key].split("T")[0]
            : "";
        }
      });

      res.data.data.orderNumber = `${res.data.data.country?.countryCode}-${res.data.data.orderNumber}LC`;

      setData(res.data.data);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const res = await uploadImgLCL.request(id, imgs);
    const newData = { ...data };
    newData.imges = res.data.data.imges;
    setData(newData);

    if (res.status === 200) {
      sweetAlert("Done");
      setImgs({});
    }
    setSpinner(false);
  };

  const approvePopupHandler = (e) => {
    e.preventDefault();
    setApproveModal(true);
  };

  useEffect(() => {
    getLCL();
  }, []);

  return (
    <div>
      {alert}
      {getOneLCL.errorAlert}
      {uploadImgLCL.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      {getOneLCL.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[content.lcl[culture]]} backBtn />
          <Container className="mt--6" fluid>
            <Card className="card-plain">
              <div className="d-flex justify-content-between">
                <h3 className={`mb-0 mt-1 ms-2 text-md-left`}>
                  {content.lcl[culture]}
                </h3>
              </div>
              <CardBody>
                <form onSubmit={approvePopupHandler}>
                  <Row>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {commenContent.orderNumber[culture]}
                        </label>
                        <Input
                          id="example3cols1Input"
                          type="text"
                          disabled
                          value={data.orderNumber || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="client-name"
                        >
                          {commenContent.customerName[culture]}
                        </label>
                        <Input
                          id="client-name"
                          placeholder={commenContent.customerName[culture]}
                          type="text"
                          value={data.customer?.customerName || ""}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="client">
                          {commenContent.customerCode[culture]}
                        </label>
                        <Input
                          id="client"
                          placeholder={commenContent.customerCode[culture]}
                          type="text"
                          value={data.customer?.customerCode || ""}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="volume">
                          {commenContent.volume[culture]}
                        </label>
                        <Input
                          id="volume"
                          type="number"
                          value={data.volume || ""}
                          onWheel={(e) => e.target.blur()}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="numberOfPkgs"
                        >
                          {commenContent.numOfPackages[culture]}
                        </label>
                        <Input
                          id="numberOfPkgs"
                          type="number"
                          value={data.numberOfPkgs || ""}
                          disabled
                          onWheel={(e) => e.target.blur()}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {content.country[culture]}
                        </label>
                        <Input
                          id="example3cols1Input"
                          placeholder="country"
                          type="text"
                          value={data.country?.name || ""}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="example3cols1Input"
                        >
                          {commenContent.followUpStatus[culture]}
                        </label>
                        <Input
                          id="example3cols1Input"
                          type="text"
                          value={
                            env.followUpStatus[thisStatusIndex]?.[culture] || ""
                          }
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="commidityType"
                        >
                          {commenContent.commodityType[culture]}
                        </label>
                        <Input
                          id="commidityType"
                          placeholder="commodity type"
                          type="select"
                          value={data.commodityType || ""}
                          disabled
                        >
                          <option disabled value>
                            -- {commenContent.selectOption[culture]} --
                          </option>
                          {env.commidityType.map((status, i) => (
                            <option key={i} value={i + 1}>
                              {status}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="rate">
                          {commenContent.rate[culture]}
                        </label>
                        <Input
                          id="rate"
                          type="text"
                          disabled
                          value={data?.rate || ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="commodity"
                        >
                          {commenContent.commodity[culture]}
                        </label>
                        <Input
                          id="commodity"
                          type="textarea"
                          disabled
                          value={data.commodity || ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
            {checkAbility("edit") && (
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between">
                    <h3 className={`mb-0 text-md-left`}>
                      {commenContent.uploadImg[culture]}
                    </h3>
                  </div>
                </CardHeader>
                <CardBody>
                  <form>
                    <Row>
                      <Col>
                        <FilePicker images={imgs} setImages={setImgs} />
                      </Col>
                    </Row>
                    <Button
                      color="success"
                      type="submit"
                      className="mt-4"
                      disabled={imgs.length === 0}
                      onClick={handleUpload}
                    >
                      <span className="btn-inner--icon me-1">
                        <i className="fas fa-long-arrow-alt-up"></i>
                      </span>
                      <span>{commenContent.save[culture]}</span>
                    </Button>
                  </form>
                </CardBody>
              </Card>
            )}
            <SpTable api={lclApi} />
            {swiftFiles.length ? (
              <DocsCard title="Swift attachments" files={swiftFiles} />
            ) : (
              ""
            )}
          </Container>
          <Gallery
            title={commenContent.swiftImage[culture]}
            imgs={swiftImges || []}
          />
          <Container>
            {files.length ? <DocsCard files={files} /> : ""}
          </Container>
          <Gallery imgs={imges} />
        </>
      )}
      {data.splcl?.length ? (
        <Card>
          <Tables
            data={data.spAir}
            headers={headers}
            columns={columns}
            setHeaders={setHeaders}
            setColumns={setColumns}
          />
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DetailLCL;
