import React, { useEffect, useState, useMemo } from "react";

// router
import { useRouteMatch } from "react-router-dom";

// get culture from utils
import getCulture from "utils/getCulture";

import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardHeader,
} from "reactstrap";
import useApi from "hooks/useApi";
import { useParams } from "react-router-dom";
import fclApi from "api/FCLBK";
import Spinner from "../../../components/Spinner";
import SimpleHeader from "components/Headers/SimpleHeader";
import env from "env";
import Gallery from "components/common/Gallery";
//content
import content from "./components/content";
import { useForm, Controller } from "react-hook-form";
//common
import TextPopup from "components/common/TextModal";
import useAlert from "hooks/useAlert";
import commenContent from "components/common/content";
import CountryTable from "components/common/CountryTable";
import PopupTable from "components/common/PopupTableUsed";
import commonContent from "components/common/content";
import bulks from "api/bulks";
import SpTable from "components/common/SpTable";

function DetailFCL() {
  let rout = useRouteMatch();
  let culture = getCulture(rout.url);

  // params
  const { id } = useParams();

  // useAlert custom hook
  const { alert, sweetAlert } = useAlert();

  // apis
  const getOneFCL = useApi(fclApi.getFCL);
  const uploadImgFCL = useApi(fclApi.uploadImgFCL);
  const approveFCL = useApi(fclApi.approveFCL);
  const updateFCLApi = useApi(fclApi.updateFCL);
  const loadedApi = useApi(fclApi.loaded);
  const arrivedBulk = useApi(bulks.arrivedBulk);
  const updateBulkArrivalDate = useApi(bulks.updateBulkArrivalDate);
  const shippedApi = useApi(fclApi.shipped);

  // const loadedApi = useApi(fclApi.loaded);

  // states
  const [data, setData] = useState({});
  const [imgs, setImgs] = useState([]);
  // const [files, setFiles] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [tableModal, setTableModal] = useState(false);
  const [countryTable, setCountryTable] = useState(false);
  const [loadedModal, setLoadedModal] = useState(false);
  const [arrivalDate, setArrivalDate] = useState("");
  const [arrivalDateModal, setArrivalDateModal] = useState(false);
  const [arrivedModal, setArrivedModal] = useState(false);
  const [shippedModal, setShippedModal] = useState(false);

  // Use form hook
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    setValue,
    reset,
  } = useForm();

  const thisStatusIndex = env.followUpStatus.findIndex(
    (status) => status.key === data.followUpStatus
  );

  const isFile = (file) => {
    const extension = file.substr(file.lastIndexOf(".") - file.length + 1);

    if (extension === "pdf") return true;
    if (extension === "docx" || extension === "dot") return true;
    if (extension === "pptx" || extension === "ppt" || extension === "ppsx")
      return true;
    return false;
  };

  const files = [...(data?.imges || "")].filter((item) => isFile(item));
  const imges = [...(data?.imges || "")].filter((img) => !isFile(img));

  const getFCL = async () => {
    const res = await getOneFCL.request(id);
    if (res.status === 200) {
      Object.keys(res.data.data).forEach((key) => {
        if (key.includes("date") || key.includes("Date")) {
          res.data.data[key] = res.data.data[key]
            ? res.data.data[key].split("T")[0]
            : "";
        }
      });
      res.data.data.orderNumber = `${res.data.data.country?.countryCode}-${res.data.data.orderNumber}FC`;
      setData(res.data.data);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const res = await uploadImgFCL.request(id, imgs);
    const newData = { ...data };
    newData.imges = res.data.data.imges;
    setData(newData);

    if (res.status === 200) {
      sweetAlert("Done");
    }

    setSpinner(false);
  };

  const approvePopupHandler = (e) => {
    e.preventDefault();
    setApproveModal(true);
  };

  const approveHandler = async (e) => {
    e.preventDefault();
    setApproveModal(false);
    setSpinner(true);

    const res = await approveFCL.request(data.fclId);
    if (res.ok) {
      sweetAlert("Done");
      setData((data) => ({
        ...data,
        followUpStatus: res.data.data.followUpStatus,
      }));
    }
    setSpinner(false);
  };

  const handleCancel = () => {
    setCountryTable(false);
    setTableModal(false);
    setLoadedModal(false);
    setShippedModal(false);
    setArrivedModal(false);
    setArrivalDateModal(false);
  };

  const handleUpdate = async (formData) => {
    setSpinner(true);
    const res = await updateFCLApi.request(id, formData);
    if (res.status === 200) {
      setData(res.data.data);
      setIsEditable(false);
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  useEffect(() => {
    getFCL();
  }, []);

  const extenstionChecker = (el) => {
    const pdf = <i className="fas fa-file-pdf text-red"></i>;
    const word = <i className="fas fa-file-word text-blue"></i>;
    const powepoint = <i className="fas fa-file-powerpoint text-orange"></i>;
    const png = <i className="fas fa-file-image text-green"></i>;
    const folder = <i className="fas fa-folder"></i>;
    const extension = el.substr(el.lastIndexOf(".") - el.length + 1);
    if (extension === "pdf") return pdf;
    else if (extension === "docx" || extension === "dot") return word;
    else if (
      extension === "pptx" ||
      extension === "ppt" ||
      extension === "ppsx"
    )
      return powepoint;
    else if (extension === "png") return png;
    else if (extension === "dir") return folder;

    return false;
  };

  const nameLength = (el, len) => {
    const name = el.split("/").at(-1);
    return name.length > len ? name.substr(0, len) + "..." : name;
  };

  const toggleForm = () => setIsEditable((val) => !val);

  const handleLoaded = () => setLoadedModal(true);

  const handleShipped = () => setShippedModal(true);

  const orderLoadedHandler = async () => {
    setSpinner(true);
    setLoadedModal(false);
    const res = await loadedApi.request(id);
    if (res.status === 200) {
      setData((d) => ({ ...d, followUpStatus: 11 }));
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  const handleArrived = async () => {
    setArrivedModal(false);
    setSpinner(true);
    const res = await arrivedBulk.request("fcl", id);

    if (res.ok) {
      setData({
        ...data,
        followUpStatus: 4,
      });
      sweetAlert("Done");
    }

    setSpinner(false);
  };

  const arrivalDataHandler = (e) => {
    e.preventDefault();
    setArrivalDateModal(true);
  };

  const arriveDateHandler = async () => {
    setArrivalDateModal(false);
    setSpinner(true);
    const res = await updateBulkArrivalDate.request("fcl", id, arrivalDate);

    if (res.ok) {
      setData({
        ...data,
        arrivalDate: `${arrivalDate}T00:00:00`,
      });
      setArrivalDate("");
      sweetAlert("Done");
    }

    setSpinner(false);
  };

  const shippedHandler = async () => {
    setSpinner(true);
    setShippedModal(false);
    const res = await shippedApi.request(id);
    if (res.status === 200) {
      setData((d) => ({
        ...d,
        followUpStatus: 7,
      }));
      sweetAlert(commenContent.done[culture]);
    }
    setSpinner(false);
  };

  useEffect(() => {
    if (!Object.keys(data).length) return;

    const updatedKeys = [
      "billLoading",
      "commodity",
      "commodityType",
      "containerPrice",
      "containerSellPrice",
      "qte",
      "size",
      "trackLine",
      "trackNumber",
      "polNumber",
      "polDate",
    ];
    updatedKeys.forEach((key) => {
      setValue(key, data[key]);
    });
    setValue("client", data.customer.customerName);
    setValue("customerId", data.customer.id);
    setValue("country", data.country.name);
    setValue("countryId", data.country.id);
    setValue("branchId", data.branch.id);
  }, [data]);

  return (
    <div>
      {alert}
      {getOneFCL.errorAlert}
      {updateFCLApi.errorAlert}
      {uploadImgFCL.errorAlert}
      {loadedApi.errorAlert}
      {shippedApi.errorAlert}
      {spinner && <Spinner gate="#29bb64" bg="#fff" opacity />}
      {getOneFCL.loading ? (
        <Spinner gate="#29bb64" bg="#fff" />
      ) : (
        <>
          <SimpleHeader parents={[content.fcl[culture]]} />
          <Container className="mt--6" fluid>
            <Card className="card-plain">
              <CardHeader>
                <h3 className="mb-0">{content.fcl[culture]}</h3>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit(handleUpdate)}>
                  <Row>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="orderNumber"
                        >
                          {commenContent.orderNumber[culture]}
                        </label>
                        <Input
                          id="orderNumber"
                          type="text"
                          readOnly
                          value={data.orderNumber || ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="follow-up-status"
                        >
                          {commenContent.followUpStatus[culture]}
                        </label>
                        <Input
                          id="follow-up-status"
                          type="text"
                          readOnly
                          value={
                            env.followUpStatus[thisStatusIndex]?.[culture] || ""
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="client">
                          {commenContent.customer[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="client"
                          rules={{
                            required: `${content.client[culture]} field is required`,
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="client"
                              placeholder={commenContent.client[culture]}
                              type="text"
                              className={errors.client && "error"}
                              value={value || ""}
                              disabled={!isEditable}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                              onClick={() => setTableModal(true)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="country">
                          {commenContent.country[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="country"
                          rules={{ required: "Country feild is required" }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="country"
                              type="text"
                              className={errors.country && "error"}
                              value={value}
                              disabled={!isEditable}
                              placeholder={`-- ${commenContent.selectAnOption[culture]} --`}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                              onClick={() => setCountryTable(true)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="branch">
                          {commenContent.branch[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="branchId"
                          rules={{ required: "branch feild is required" }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="branch"
                              type="select"
                              disabled={!isEditable}
                              className={errors.branchId && "error"}
                              value={value || "disabled"}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            >
                              <option disabled value="disabled">
                                -- {commenContent.selectAnOption[culture]} --
                              </option>
                              {data.branches?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </Input>
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="billLoading"
                        >
                          {content.billLoading[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="billLoading"
                          rules={{ required: "bill Loading feild is required" }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="billLoading"
                              type="text"
                              className={errors.billLoading && "error"}
                              value={value || ""}
                              disabled={!isEditable}
                              placeholder={content.billLoading[culture]}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="quantity"
                        >
                          {content.quantity[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="qte"
                          rules={{
                            required: "quantity feild is required",
                            pattern: {
                              value: /^[1-9](\d)*$/,
                            },
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="quantity"
                              type="number"
                              className={errors.qte && "error"}
                              value={value}
                              disabled={!isEditable}
                              placeholder={content.quantity[culture]}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="polNumber"
                        >
                          {content.polNumber[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="polNumber"
                          rules={{
                            required: "pol Number feild is required",
                            pattern: {
                              value: /^[1-9](\d)*$/,
                            },
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="quantity"
                              type="text"
                              className={errors.polNumber && "error"}
                              value={value}
                              disabled={!isEditable}
                              placeholder={content.polNumber[culture]}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="polDate">
                          {content.polDate[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="polDate"
                          rules={{
                            required: "polDate feild is required",
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="quantity"
                              type="date"
                              className={errors.polDate && "error"}
                              value={value}
                              disabled={!isEditable}
                              placeholder={content.polDate[culture]}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="commidityType"
                        >
                          {commenContent.commodityType[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="commodityType"
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="commidityType"
                              type="select"
                              disabled={!isEditable}
                              value={value || "disabled"}
                              onChange={({ target: { value } }) =>
                                onChange(+value)
                              }
                            >
                              <option disabled value="disabled">
                                -- {commenContent.selectAnOption[culture]} --
                              </option>
                              {env.commidityType.map((status, i) => (
                                <option key={i} value={i + 1}>
                                  {status}
                                </option>
                              ))}
                            </Input>
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="trackNumber"
                        >
                          {content.trackNumber[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="trackNumber"
                          rules={{
                            required: "Track number feild is required",
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="trackNumber"
                              placeholder={content.trackNumber[culture]}
                              type="text"
                              className={errors.trackNumber && "error"}
                              value={value || ""}
                              disabled={!isEditable}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="trackNumber"
                        >
                          {content.trackLine[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="trackLine"
                          rules={{
                            required: "Track line feild is required",
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="trackLine"
                              placeholder={content.trackLine[culture]}
                              type="select"
                              className={errors.trackLine && "error"}
                              value={+value || "disabled"}
                              disabled={!isEditable}
                              onChange={({ target: { value } }) =>
                                onChange(+value)
                              }
                            >
                              <option disabled value="disabled">
                                {`-- ${commenContent.selectOption[culture]} --`}
                              </option>
                              {env.trackLine.map((item, index) => (
                                <option key={item} value={index + 1}>
                                  {item}
                                </option>
                              ))}
                            </Input>
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="size">
                          {commenContent.size[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="size"
                          rules={{ required: "Size feild is required" }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="size"
                              placeholder={commenContent.size[culture]}
                              type="select"
                              className={errors.size && "error"}
                              value={value || "disabled"}
                              disabled={!isEditable}
                              onChange={({ target: { value } }) =>
                                onChange(+value)
                              }
                            >
                              <option disabled value="disabled">
                                {`-- ${commenContent.selectOption[culture]} --`}
                              </option>
                              {env.size.map((size, index) => (
                                <option key={size} value={index + 1}>
                                  {size}
                                </option>
                              ))}
                            </Input>
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="price-value"
                        >
                          {content.containerPrice[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="containerPrice"
                          rules={{
                            required: "container Price value field is required",
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            },
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="price-value"
                              placeholder={content.containerPrice[culture]}
                              type="number"
                              className={errors.containerPrice && "error"}
                              value={value || ""}
                              disabled={!isEditable}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="price-value"
                        >
                          {content.containerSellPrice[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="containerSellPrice"
                          rules={{
                            required:
                              "container sell Price value field is required",
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            },
                          }}
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="price-value"
                              placeholder={content.containerSellPrice[culture]}
                              type="number"
                              className={errors.containerSellPrice && "error"}
                              value={value || ""}
                              disabled={!isEditable}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                              onWheel={(e) => e.target.blur()}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="rate">
                          {commenContent.rate[culture]}
                        </label>
                        <Input
                          id="rate"
                          type="text"
                          disabled
                          value={data?.rate || ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="commodity"
                        >
                          {commenContent.commodity[culture]}
                        </label>
                        <Controller
                          control={control}
                          name="commodity"
                          render={({
                            field: { ref, onChange, value, ...field },
                          }) => (
                            <Input
                              {...field}
                              id="commodity"
                              type="textarea"
                              style={{ resize: "none" }}
                              value={value}
                              disabled={!isEditable}
                              onChange={({ target: { value } }) =>
                                onChange(value)
                              }
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {isEditable ? (
                    <>
                      <Button
                        color="success"
                        type="submit"
                        disabled={!isDirty}
                        className="me-1"
                      >
                        <span>{commenContent.update[culture]}</span>
                      </Button>
                      <Button
                        color="danger"
                        type="button"
                        onClick={toggleForm}
                        className="me-1"
                      >
                        <span>{commenContent.cancel[culture]}</span>
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="info"
                      type="button"
                      onClick={toggleForm}
                      className="me-1"
                    >
                      <span className="btn-inner--icon me-1">
                        <i className="fas fa-pencil-alt" />
                      </span>
                      <span>{commenContent.edit[culture]}</span>
                    </Button>
                  )}
                  {data.followUpStatus === 5 && (
                    <Button type="button" onClick={handleLoaded}>
                      <span>{content.loaded[culture]}</span>
                    </Button>
                  )}
                  {data.followUpStatus === 11 && (
                    <Button type="button" onClick={handleShipped}>
                      <span>{content.shipped[culture]}</span>
                    </Button>
                  )}
                </form>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between">
                  <h3 className={`mb-0 text-md-left`}>
                    {content.uploadImgs[culture]}
                  </h3>
                </div>
              </CardHeader>
              <CardBody>
                <form>
                  <Row>
                    <Col>
                      <input
                        type="file"
                        multiple
                        onChange={(e) => setImgs(e.target.files)}
                      />
                    </Col>
                  </Row>
                  <Button
                    color="success"
                    type="submit"
                    className="mt-4"
                    disabled={imgs.length === 0}
                    onClick={handleUpload}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-long-arrow-alt-up"></i>
                    </span>
                    <span>Update</span>
                  </Button>
                </form>
              </CardBody>
            </Card>
            {data.followUpStatus === 7 && (
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between">
                    <h3 className={`mb-0 text-md-left`}>
                      {content.arrival[culture]}
                    </h3>
                  </div>
                </CardHeader>
                <CardBody>
                  <form onSubmit={arrivalDataHandler}>
                    <Row>
                      <Col md="6" lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="client"
                          >
                            {commenContent.dateArrival[culture]}
                          </label>
                          <Input
                            id="client"
                            type="date"
                            disabled={data.followUpStatus === 4 ? true : false}
                            value={
                              arrivalDate ||
                              data.arrivalDate?.split("T")[0] ||
                              ""
                            }
                            onChange={(e) => setArrivalDate(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          color="success"
                          type="button"
                          className="mt-4 me-1"
                          onClick={() => setArrivedModal(true)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-check"></i>
                          </span>
                          <span>{commenContent.arrived[culture]}</span>
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          className="mt-4"
                          disabled={!arrivalDate}
                        >
                          <span>{commenContent.updateDate[culture]}</span>
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            )}
            <SpTable api={fclApi} />
            <Card>
              <CardBody>
                <div className="attachment d-flex flex-nowrap mb-5">
                  {files.map((file, index) => (
                    <div
                      key={index}
                      className="d-flex flex-column text-center mr-4 ml-4"
                    >
                      <a href={file}>{extenstionChecker(file)}</a>
                      <a href={file}>{nameLength(file, 10)}</a>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Container>
          <Gallery imgs={imges} />
        </>
      )}
      <TextPopup
        modal={tableModal}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <PopupTable
            setCustomer={(customer) => setValue("customerId", customer.id)}
            setTableModal={setTableModal}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      <TextPopup
        modal={countryTable}
        text="Next"
        handleCancel={handleCancel}
        fn={() => {}}
        color="primary"
        noBtn
      >
        <Container>
          <CountryTable
            setCountryTable={setCountryTable}
            setValue={setValue}
            sweetAlert={sweetAlert}
          />
        </Container>
      </TextPopup>
      <TextPopup
        fn={orderLoadedHandler}
        modal={loadedModal}
        text={commenContent.continue[culture]}
        handleCancel={handleCancel}
        color="primary"
      >
        <Container>{content.loadedPopup[culture]}</Container>
      </TextPopup>
      <TextPopup
        modal={arrivedModal}
        text={commonContent.yes[culture]}
        handleCancel={handleCancel}
        fn={handleArrived}
        color="success"
      >
        <Container>
          <h3>{content.arrivedPopup[culture]}</h3>
        </Container>
      </TextPopup>
      <TextPopup
        modal={arrivalDateModal}
        text={commonContent.update[culture]}
        handleCancel={handleCancel}
        fn={arriveDateHandler}
        color="success"
      >
        <Container>
          <h3>
            {content.datePopup[culture]}
            <span style={{ color: "var(--primary)" }}> {arrivalDate}</span>
          </h3>
        </Container>
      </TextPopup>
      <TextPopup
        modal={shippedModal}
        text={commonContent.update[culture]}
        handleCancel={handleCancel}
        fn={shippedHandler}
        color="success"
      >
        <Container>
          <h3>{content.shippedPopup[culture]}</h3>
        </Container>
      </TextPopup>
    </div>
  );
}

export default DetailFCL;
